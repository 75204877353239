'use strict';

/*******************************************************************************************/
class UxrEeSelect extends React.Component {
	constructor(props) {
		super(props);
		this.defaultValue = '';
		this.state = {
			'value': this.props.value ||this.props.defaultValue || this.defaultValue,
		};
		
		this.onChange = this.onChange.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		
		return (
			<div {...this.props.htmlProps}>
				<UxrEe_.Select.default options={this.props.options} multi={this.props.multi} clearable={this.props.clearable} value={this.state.value.options} onChange={this.onChange} />
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onChange(value) {
		value = {options:value}; //Array is a "reserved word"
		this.setState({
			'value': value
		})
		this.props.onChange(this, {value});
	}
	
} //class
